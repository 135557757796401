<template>
  <static-fullscreen-card @sendHeight="setH($event)" v-if="m">
    <template v-slot:header>11</template>

    <template v-slot:actions>
      <a-btn-make-offer v-if="getAccess('object.link')" @onData="newOffer($event)" />
      <v-btn color="primary" @click="fetchData()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :idShow="idEdit" @close="itemShowClose($event)" @makeMyOffer="openMyOffer($event)" />
      <ViewMyOffer v-if="showMyOfferDialog" v-model="showMyOfferDialog" :idShow="idMyOffer" @close="showMyOfferDialog = false" />
    </portal>
    <a-loader v-if="loading" />
    <v-data-table
      ref="dataTable"
      :headers="headers"
      :items="items"
      class="elevation-1"
      :height="cardHeight - 35"
      hide-default-footer
      fixed-header
      disable-sort
      disable-pagination
      disable-filtering
      @click:row="onClickRow"
      @scroll="onScroll"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.createdon="{ item }"> <a-view-table-datetime :value="item.createdon" :model="model.find(m => m.name == 'createdon')" /> </template>
      <template v-slot:item.category_id="{ item }"> <a-view-table-select :value="item.category_id" :model="model.find(m => m.name == 'category_id')" /> </template>
      <template v-slot:item.user_id="{ item }"> <a-view-table-select :value="item.user_id" :model="model.find(m => m.name == 'user_id')" /> </template>
      <template v-slot:item.actions1="{ item }">
        <v-btn
          v-if="item.phone > 1"
          class="ma-1"
          dark
          :small="true"
          :color="phoneStatus.phone == parseInt(item.phone) ? 'yellow' : 'error'"
          @click.stop="onItemCall(item)"
        >
          Вызов
        </v-btn>
        <v-btn
          v-if="false"
          class="ma-1"
          fab
          dark
          :x-small="$vuetify.breakpoint.mdAndDown || true"
          :small="!$vuetify.breakpoint.mdAndDown && false"
          color="primary"
          :href="item.vendor == 'avito' ? 'https://avito.ru/' + item.url : item.url"
          target="_blank"
        >
          <v-icon dark> mdi-open-in-new </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </static-fullscreen-card>
</template>

<script>
import { getAccess, doubleClickDetect, keyDetect, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, doubleClickDetect, keyDetect, genModel],
  components: {
    editDialog: () => import("./views/objectView.vue"),
    ViewMyOffer: () => import("./views/myOfferView.vue"),
  },
  data() {
    return {
      idEdit: 0,
      idMyOffer: 0,
      cardHeight: 40,
      showEditDialog: false,
      showMyOfferDialog: false,
      page: 1,
      m: this.$store.getters["config/get"].models.object,
      pager: { page: 0, count: 0 },
      type: "object",
      defaults: {
        sort: { key: "id", order: "DESC" },
        filters: this.getAccess("object.viewAll") ? {} : { user_id: this.$root.profile.id },
        paramName: "objects",
      },
      items: [],
    };
  },
  computed: {
    url() {
      return this.m.url;
    },
    model() {
      let res = this.getModelList(this.m);
      try {
        if (this.$vuetify.breakpoint.xsOnly) res.find(r => r.name == "icon").hidden = true;
      } catch (error) {}
      if (this.$root.profile.role === 1000) res.find(r => r.name == "parser_type").hidden = false;
      return res;
    },
    headers() {
      let res = this.model
        .filter(m => !m.hidden)
        .map(m => {
          return { text: m.title, value: m.name, class: m.class, width: m.width };
        });
      return res;
    },
  },
  created() {
    this.$root.title = "Избранное";
    this.fetchData();
  },
  mounted() {
    // Загрузка начальных данных
    //this.loadItems();

    // Привязываем событие scroll
    const scrollContainer = this.$refs.dataTable.$el.querySelector(".v-data-table__wrapper");
    scrollContainer.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    // Удаляем событие scroll перед уничтожением компонента
    const scrollContainer = this.$refs.dataTable.$el.querySelector(".v-data-table__wrapper");
    scrollContainer.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll(event) {
      const element = event.target;
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.loadItems(); // Загружаем новые данные, когда прокручиваем до конца
      }
    },
    async loadItems() {
      if (this.loading) return; // Проверка состояния
      console.log(this.pager);
      if (this.pager.count <= this.pager.limit * this.page) return;
      this.loading = true;

      // Симуляция запроса на сервер
      await this.fetchData(this.page + 1); // Имитация функции запроса данных

      // Если данные вернулись пустыми, значит больше нечего загружать
    },
    openMyOffer(id) {
      if (!id) return;
      this.idMyOffer = id;
      this.showMyOfferDialog = true;
    },
    setH(e) {
      console.log("setH", e);
      this.cardHeight = e;
    },
    itemShowClose() {
      this.showEditDialog = false;
      this.loading = true;
      //this.$refs.table.updateRowData(this.idEdit);
      this.loading = !true;
      this.idEdit = 0;
    },
    refreshTable() {
      this.$refs.table.updateData();
    },
    onClickRow(d) {
      this.showEditDialogFun(d.id);
      return;
      this.showEditDialogFun(d.row.id);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
    async newOffer(d) {
      await this.$axios.post(this.url, d);
      this.fetchData();
    },

    async fetchData(page = 1) {
      this.loading = true;
      let where = this.where || this.defaults?.where || {};
      let filters = Object.assign({}, this.defaults.filters, where);
      //let filters = Object.assign({}, this.filters);
      let params = {
        sort: JSON.stringify({ key: "createdon", order: "desc" }),
        filters,
        pager: { page: page - 1, limit: 50 },
      };

      let response = await this.$axios.get(this.url, { params });
      let data;
      let items = [];
      if ((data = response.data.data)) {
        items = page == 1 ? data : [...this.items, ...data];
        this.pager = response.data.pager;
        this.page = page;
      }
      this.items = items;
      this.loading = false;
    },
  },
};
</script>
